import { Roles } from 'models/User';

import { Props as ModalProps } from 'components/layout/Modal';
import { Props as FormFooterProps } from 'components/modules/public/layout/forms/FormFooter';
import { Props as LoginFormProps } from 'components/modules/public/layout/forms/LoginForm';

import Component from './component';

export interface Props {
    modalProps: ModalProps;
    loginFormProps: LoginFormProps;
    formFooter: FormFooterProps;
    roleType?: Roles,
    onApply?: () => any;
    onSuccess?: () => any;
}

Component.defaultProps = {
    onApply: null,
    onSuccess: null,
    roleType: Roles.Worker,
};

export default Component;