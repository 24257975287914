import { FunctionComponent } from 'react';
import dynamic from 'next/dynamic';

import { Roles } from 'models/User';

import Spinner from 'components/layout/Spinner/component';
import FormFooter from 'components/modules/public/layout/forms/FormFooter';
import LoginForm from 'components/modules/public/layout/forms/LoginForm';

import { Props } from './index';
import StyledComponent from './styles';

const DynamicModal = dynamic(
    () => import('components/layout/Modal'),
    { loading: () => <Spinner /> }
);

const PublicLayoutModalsJobLoginModal: FunctionComponent<Props> = ({ modalProps, loginFormProps, formFooter, onApply, onSuccess, roleType }) => {
    return (
        <StyledComponent className="public-layout-modals-job-login-modal">
            <DynamicModal
                title={modalProps?.title}
                {...modalProps}
            >
                <LoginForm
                    {...loginFormProps}
                    roleType={roleType}
                    onSuccess={() => {
                        onSuccess();
                        onApply && onApply();
                    }}
                    isLoginPhone={roleType === Roles.Company ? false : true}
                />
                {formFooter && (
                    <FormFooter
                        {...formFooter}
                        roleType={roleType}
                        onSuccess={onSuccess}
                    />
                )}
            </DynamicModal>
        </StyledComponent>
    );
};

export default PublicLayoutModalsJobLoginModal;