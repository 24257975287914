import { Roles } from 'models/User';

import { Props as ButtonProps } from 'components/layout/Button';

import Component from './component';

export interface Props {
    buttonProps: {
        login: {
            facebook: ButtonProps;
            google: ButtonProps;
        };
        register: ButtonProps;
    };
    title: string;
    subtitle: string;
    roleType?: Roles;
    recaptchaToken?: string;
    onSuccess: Function;
}

Component.defaultProps = {};

export default Component;