import { FunctionComponent } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import getConfig from 'next/config';

import { FacebookResponseBody } from 'types/request';

import { RegisterTypes, Roles } from 'models/User';

import { Props, RenderProps } from './index';
import StyledComponent from './styles';

const LayoutFacebookLogin: FunctionComponent<Props> = ({ actions, onRender, ReCaptchaValue, onSuccess }) => {
    const { publicRuntimeConfig } = getConfig();

    return (
        <StyledComponent className="layout-facebook-login">
            <FacebookLogin
                appId={publicRuntimeConfig.FACEBOOK_APP_ID}
                isMobile={false}
                callback={(response: FacebookResponseBody) =>
                    actions.login({
                        token: response?.accessToken,
                        tokenType: RegisterTypes.Facebook,
                        recaptchaToken: ReCaptchaValue,
                        role: Roles.Worker,
                    })
                        .then(() => onSuccess())
                }
                render={(renderProps: RenderProps) => onRender(renderProps)}
            />
        </StyledComponent>
    );
};

export default LayoutFacebookLogin;