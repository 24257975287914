import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
	width: 100%;

	.adblock-info {
        display: flex;
		flex-flow: column wrap;
		place-items: center;
		margin: 1em auto 2em auto;

        .adblock-title {
            color: ${vars.colorAlert};
			text-align: center;
			font-weight: 600;
			margin: 0 0 1em 0;
			font-size: 1.1em;
        }
    }

	.error {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 1rem;
		padding-bottom: 2em;
		color: ${vars.colorAlert};

		.error-message {
			font-size: 1.1em;
		}
		.error-icon {
			height: 1.1em;
		}
	}

	.footer-title {
		font-size: 1.1em;
		text-align: center;
		padding: 1em 0;
	}

	.layout-google-login,
	.layout-facebook-login {
		margin: 0 auto 1em auto;
		max-width: 60%;
	}

	.footer {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.subtitle {
			padding-right: 0.3em;
		}

		.register-link {
			.button {
				padding: 0;

				.button-content {
					font-size: 1em;
				}

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	@media all and (max-width: ${vars.tabletL}) {
		.adblock-info {
			padding: 0 3em;
		}

		.layout-google-login,
		.layout-facebook-login {
			margin: 1em auto 1em auto;
			max-width: 90%;
		}
	}

	.footer-title {
		padding: 0.2em;
	}

	.footer {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 0 0 2em 0;

		.register-link,
		.button {
			margin: 0 0.5em;
			.button-content {
				padding: 0;
				margin: 0;
				text-decoration: underline;
			}
		}
	}
`;
