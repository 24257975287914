import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { login, LoginParams } from 'store/modules/user/actions';

import { Props as ButtonProps } from 'components/layout/Button';

import Component from './component';

export interface Props extends ParentProps {
    actions: {
        login: (params: LoginParams) => Promise<any>;
    };
}

export interface ParentProps {
    buttonProps: ButtonProps;
    ReCaptchaValue: string;
    onSuccess: Function;
    onError?: Function;
}

export interface RenderProps {
    onClick: () => any;
}

const mapDispatchToProps = (dispatch: Dispatch): object => {
    return {
        actions: {
            login: bindActionCreators(login, dispatch),
        },
    };
};

Component.defaultProps = {};

export default connect(null, mapDispatchToProps)(Component);