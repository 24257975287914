import { FunctionComponent } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';

import { RegisterTypes, Roles } from 'models/User';

import Button, { ButtonLayouts } from 'components/layout/Button';

import { Props } from './index';
import StyledComponent from './styles';

interface StateTypes {
    data: {
        given_name: string;
        family_name: string;
        email: string;
        sub: string;
    };
}
const LayoutGoogleLogin: FunctionComponent<Props> = ({ actions, buttonProps, ReCaptchaValue, onSuccess }) => {
    const googleLogin = useGoogleLogin({
        onSuccess: async ({ access_token }) => {
            const userInfo : StateTypes = await axios.get(
                'https://www.googleapis.com/oauth2/v3/userinfo',
                { headers: { Authorization: `Bearer ${access_token}` } },
            );
            actions.login({
                googleId: userInfo?.data?.sub,
                role: Roles.Worker,
                tokenType: RegisterTypes.Google,
                recaptchaToken: ReCaptchaValue,
            })
                .then(() => onSuccess());
        },
        onError: errorResponse => console.log(errorResponse),
    });
    return (
        <StyledComponent className="layout-google-login">
            <Button
                onClick={()=>googleLogin()}
                layout={ButtonLayouts.Wide}
                {...buttonProps}
            />
        </StyledComponent>
    );
};

export default LayoutGoogleLogin;