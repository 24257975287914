import { FunctionComponent } from 'react';
import getConfig from 'next/config';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useDetectAdBlock } from 'adblock-detect-react';

import { Roles } from 'models/User';

import Button from 'components/layout/Button';
import FacebookLogin, { RenderProps as FacebookRenderProps } from 'components/layout/FacebookLogin';
import GoogleLogin from 'components/layout/GoogleLogin';

import { Props } from './index';
import StyledComponent from './styles';


const PublicLayoutFormsFormFooter: FunctionComponent<Props> = ({
    buttonProps,
    title,
    subtitle,
    roleType,
    recaptchaToken,
    onSuccess,
}) => {
    const { publicRuntimeConfig } = getConfig();
    const isAdblock = useDetectAdBlock();
    return (
        <StyledComponent
            className="public-layout-forms-form-footer"
        >
            <div
                className="footer-title"
                dangerouslySetInnerHTML={{ __html: title }}
            />
            {
                !isAdblock && roleType === Roles.Worker && (
                    <>
                        <FacebookLogin
                            ReCaptchaValue={recaptchaToken}
                            onSuccess={onSuccess}
                            onRender={(renderProps: FacebookRenderProps) => (
                                <Button
                                    {...buttonProps?.login?.facebook}
                                    onClick={renderProps.onClick}
                                />
                            )}

                        />
                        <GoogleOAuthProvider clientId={publicRuntimeConfig.GOOGLE_APP_ID}>
                            <GoogleLogin
                                ReCaptchaValue={recaptchaToken}
                                onSuccess={onSuccess}
                                buttonProps={buttonProps?.login?.google}
                            />
                        </GoogleOAuthProvider>
                    </>
                )
            }
            {
                isAdblock && (
                    <div className="adblock-info">
                        <p className="adblock-title">Twoja przeglądarka wykorzystuje narzędzie do blokowania reklam.</p>
                        <p>Jeśli chcesz skorzystać z logowania lub rejestracji przez Facebook oraz Google to wyłącz narzędzie do blokowania reklam.</p>
                    </div>
                )
            }
            <div className="footer">
                <div
                    className="subtitle"
                    dangerouslySetInnerHTML={{ __html: subtitle }}
                />
                <Button
                    className="register-link"
                    {...buttonProps?.register}
                />
            </div>
        </StyledComponent>
    );
};

export default PublicLayoutFormsFormFooter;