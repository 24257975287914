import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
	.layout-modal {
		.modal-container {
			max-width: 35em;
			top: 50%;

			.modal-header {
				justify-content: flex-start;
			}
			.layout-forms-input-wrapper {
				margin-bottom: 0;
			}
		}

		.layout-facebook-login,
		.layout-google-login {
			margin-bottom: 1rem;
		}
	}

	@media all and (max-width: ${vars.mobileL}) {
		.layout-modal {
			.modal-container {
				top: 6%;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}
`;
